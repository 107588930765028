.p-logistics {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: calc(0.133333rem + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

h5 {
  font-size: 1rem;
  display: inline-block;
  width: 10rem;
}

.p-logistics .header {
  padding-top: constant(safe-area-inset-top);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 1.146667rem;
  width: 100%;
  background-color: #9a0009;
  z-index: 100;
  text-align: left;
}

.p-logistics .header .logo-wrap {
  height: 1.146667rem;
}

.p-logistics .header .logo {
  height: 1.146667rem;
  margin-left: 0.973333rem;
}

.p-logistics .header-placeholder {
  padding-top: constant(safe-area-inset-top);
  height: 1.146667rem;
  width: 100%;
}

.p-logistics .content {
  padding: 0 0 1.146667rem;
  margin: 0 auto;
  max-width: 18.906667rem;
  min-height: calc(100vh - 4.293333rem);
  background-color: #f4f4f4;
}

.p-logistics .content .search {
  width: 12.4rem;
  max-width: 90%;
  height: 1.066667rem;
  padding: 0.946667rem 0 0.48rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  border-radius: 0.08rem;
}

.p-logistics .content .search .search-input {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 1.066667rem;
  border: 0.026667rem solid #949494;
  border-radius: 0.08rem 0 0 0.08rem;
  box-sizing: border-box;
  padding: 0.333333rem;
  border-right: none;
  background-color: #fff;
}

.p-logistics .content .search .search-button {
  -webkit-flex: 0 0 2.613333rem;
  flex: 0 0 2.613333rem;
  background-color: #9a0009;
  color: #fff;
  font-size: 0.346667rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-weight: 500;
  border-radius: 0 0.08rem 0.08rem 0;
}

.p-logistics .content .search .close-icon {
  position: absolute;
  width: 0.293333rem;
  height: 0.293333rem;
  right: 2.933333rem;
  padding: 0.16rem;
}

.p-logistics .content .history {
  width: 12.4rem;
  max-width: 90%;
  margin: 0 auto 0.32rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p-logistics .content .history .item {
  position: relative;
  background-color: #fff;
  font-size: 0.32rem;
  padding: 0.146667rem 0.933333rem 0.146667rem 0.4rem;
  color: #6d6d6d;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 0.293333rem;
  margin: 0 0.306667rem 0.213333rem 0;
}

.p-logistics .content .history .item .close-icon {
  position: absolute;
  right: 0.213333rem;
  width: 0.16rem;
  height: 0.186667rem;
  margin-left: 0.186667rem;
  padding: 0.16rem;
  z-index: 1;
}

.p-logistics .content .card {
  width: 17.6rem;
  max-width: 95%;
  background-color: #fff;
  border-radius: 0.48rem;
  margin: 0 auto;
}

.p-logistics .content .card.empty img {
  padding: 3.733333rem 0;
  height: 5.333333rem;
  width: 5.333333rem;
  margin: 0 auto;
}

.p-logistics .content .card.empty .text {
  font-size: 0.4rem;
  padding: 6rem 0;
  color: #b1b1b1;
  font-weight: bold;
}

.p-logistics .footer {
  margin: 0.266667rem 0;
  height: 1.333333rem;
  font-size: 0.32rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 0.533333rem;
}

.p-logistics .footer img {
  width: 0.4rem;
  height: 0.4rem;
}

@media screen and (max-width: 470px) {
  .p-logistics .header .logo-wrap {
    width: 90%;
    height: 1.146667rem;
    margin: 0 auto;
  }

  .p-logistics .header .logo {
    height: 1.146667rem;
    margin-left: 0;
  }

  .p-logistics .content .card {
    max-width: 90%;
  }

  .p-logistics .content .search {
    padding: 0.346667rem 0 0.293333rem;
  }

  .p-logistics .content .history {
    margin: 0 auto 0.053333rem;
  }
}