.c-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.c-loading .mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0);
}

.c-loading .loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
  width: 3.466667rem;
  height: 3.733333rem;
  background: rgba(0,0,0,.8);
  border-radius: 0.533333rem;
  color: rgba(255,255,255,.7);
  font-size: 0.426667rem;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.c-loading .loading-content .image-wrap {
  position: relative;
  width: 1.333333rem;
  height: 1.333333rem;
  overflow: hidden;
  margin-bottom: 0.133333rem;
}

.c-loading .loading-content .image-wrap .image-box img {
  display: inline-block;
  width: 1.2rem;
}