.m-segmented {
  width: 13.066667rem;
  margin: auto;
  padding-bottom: 0.666667rem;
}

.m-segmented .segment-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}

.m-segmented .segment-item:first-child .timeline .circle {
  -webkit-flex: 0 0 11PX;
  flex: 0 0 11PX;
  width: 11PX;
  height: 11PX;
  background: #fff;
  border: 3PX solid #9a0009;
}

.m-segmented .segment-item:first-child .detail .main-info {
  color: #5d5d5d;
}

.m-segmented .segment-item:first-child .detail .time {
  color: #a6a6a6;
}

.m-segmented .segment-item .timeline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 0.56rem;
  width: 0.8rem;
  -webkit-flex: 0 0 0.8rem;
  flex: 0 0 0.8rem;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
}

.m-segmented .segment-item .timeline .line {
  display: inline-block;
  width: 2PX;
  height: 100%;
  background-color: #d5d5d5;
}

.m-segmented .segment-item .timeline .line.invisible {
  opacity: 0;
}

.m-segmented .segment-item .timeline .line-top {
  margin-bottom: 0.04rem;
}

.m-segmented .segment-item .timeline .line-bottom {
  margin-top: 0.04rem;
}

.m-segmented .segment-item .timeline .icon {
  -webkit-flex: 0 0 0.666667rem;
  flex: 0 0 0.666667rem;
  width: 0.666667rem;
  height: 0.666667rem;
  margin: 0.04rem 0;
}

.m-segmented .segment-item .timeline .circle {
  -webkit-flex: 0 0 6PX;
  flex: 0 0 6PX;
  box-sizing: border-box;
  width: 6PX;
  height: 6PX;
  background-color: #bebebe;
  border-radius: 50%;
}

.m-segmented .segment-item .detail {
  margin-left: 1.36rem;
  margin-bottom: 1.333333rem;
  position: relative;
  text-align: left;
  top: 0.6rem;
}

.m-segmented .segment-item .detail .main-info {
  font-size: 0.4rem;
  font-weight: bold;
  color: #bebebe;
}

.m-segmented .segment-item .detail .time {
  position: absolute;
  font-size: 0.293333rem;
  font-weight: bold;
  color: #bebebe;
  bottom: -0.426667rem;
}

@media screen and (max-width: 470px) {
  .m-segmented {
    width: 8rem;
    padding-left: 0;
  }
}