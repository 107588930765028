.toast-container {
  position: absolute;
  z-index: 99999;
  top: 0;
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.toast-wrap {
  position: fixed;
  top: 40%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  z-index: 99999;
}

.toast-item {
  padding: 0.16rem 0.32rem;
  background-color: rgba(18,18,18,.7);
  border-radius: 0.08rem;
  color: #fff;
  font-size: 0.4rem;
}