.m-package-detail {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 0.88rem 1.266667rem 0.666667rem;
  border-bottom: 0.026667rem dashed #f1f1f1;
}

.m-package-detail .state-wrap {
  width: 100%;
  text-align: left;
}

.m-package-detail .state-wrap .state {
  font-size: 0.426667rem;
  color: #b4000b;
  font-weight: bold;
  margin-bottom: 0.12rem;
  position: relative;
}

.m-package-detail .state-wrap .state:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 0.133333rem;
  height: 0.133333rem;
  border-radius: 50%;
  background-color: #b4000b;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -0.293333rem;
}

.m-package-detail .state-wrap .time {
  font-size: 0.24rem;
  font-weight: 500;
  color: #949494;
}

.m-package-detail .package-info-wrap {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 0.466667rem;
}

.m-package-detail .package-info-wrap .package-no {
  font-size: 0.266667rem;
  color: #2d2d2d;
  font-weight: 500;
  white-space: nowrap;
}

.m-package-detail .package-info-wrap .package-location {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 0.32rem;
  color: #2d2d2d;
  font-weight: 800;
  white-space: nowrap;
}

.m-package-detail .package-info-wrap img {
  width: 0.266667rem;
  height: 0.213333rem;
  margin: 0 0.133333rem;
}

@media screen and (max-width: 470px) {
  .m-package-detail {
    padding: 0.32rem 0.533333rem 0.453333rem;
  }

  .m-package-detail .package-info-wrap .package-no {
    margin-bottom: 0.066667rem;
  }

  .m-package-detail .package-info-wrap .package-location {
    margin-bottom: 0.066667rem;
  }

  .m-package-detail .state-wrap {
    width: 100%;
  }

  .m-package-detail .state-wrap .state::before {
    display: none;
  }
}