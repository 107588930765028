.m-progress {
  width: 12.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  margin: 1.066667rem auto 0.533333rem;
}

.m-progress .progress-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex: 0 0;
  flex: 0 0;
}

.m-progress .progress-item .icon-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.346667rem;
}

.m-progress .progress-item .icon-wrap .line {
  width: 2.4rem;
  border-top: 0.026667rem dotted #9f9f9f;
  box-sizing: border-box;
}

.m-progress .progress-item .icon-wrap .line.invisible {
  opacity: 0;
}

.m-progress .progress-item .icon-wrap .circle {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 0.373333rem;
  height: 0.373333rem;
  border: 0.026667rem solid #118e00;
  background-color: #118e00;
  border-radius: 50%;
  margin: 0.08rem;
}

.m-progress .progress-item.unavailable .circle {
  background-color: #fff;
  border-color: #5cbd22;
}

.m-progress .progress-item.unavailable .text {
  color: #bebebe;
}

.m-progress .progress-item.active .text {
  top: -0.146667rem;
}

.m-progress .progress-item.active .circle {
  position: relative;
  width: 0.666667rem;
  height: 0.666667rem;
}

.m-progress .progress-item.active .circle:after {
  content: "";
  display: inline-block;
  width: 0.666667rem;
  height: 0.666667rem;
  background: url("../assets/images/checkmark.png") 100%/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.m-progress .progress-item .text {
  font-size: 0.266667rem;
  font-weight: bold;
  color: gray;
  width: 2.133333rem;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  -webkit-transform: translateX(-56%);
  transform: translateX(-56%);
}

@media screen and (max-width: 470px) {
  .m-progress {
    width: 8rem;
    margin-top: 0.72rem;
  }

  .m-progress .progress-item .icon-wrap .line {
    width: 1.2rem;
  }

  .m-progress .progress-item .text {
    width: 1.333333rem;
    -webkit-transform: translateX(-45%);
    transform: translateX(-45%);
  }
}