body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
p,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'PingFang SC', Roboto, 'Helvetica Neue', Helvetica, STHeiti,
    sans-serif;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

img {
  border: none;
}

input {
  outline: none;
  border-radius: 0;
  background-color: inherit;
  box-shadow: none;
  -webkit-box-shadow: none;
}

a,
img {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

i {
  font-style: normal;
}

#root {
  margin: 0;
  padding: 0;
}

.textCenter {
  text-align: center;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.flexItem {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.clearFix:after {
  display: block;
  height: 0;
  content: '';
  clear: both;
}

/* !ios点击高亮 */

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}